.hero-grid {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.hero-container {
    background-color: transparent;
    z-index: 0;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -3;
    filter: opacity(60%) grayscale(80%);
}

img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}